@import '@/styles/artifacts.scss';






























.title {
  font-size: fontSize(fs-150);
}
.subtitle {
  font-size: fontSize(fs-50);
  color: colorVodafone(vodafone-red);
}
.phone-number {
  font-size: fontSize(fs-300);
}
.schedule {
  font-size: fontSize(fs-50);
}
.link {
  text-decoration: none;
}
