@import '@/styles/artifacts.scss';






















.title {
  font-size: fontSize(fs-150);
}
.subtitle {
  font-size: fontSize(fs-50);
}
